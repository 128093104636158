<template>
  <div>
    <template v-if="mounted">
      <breadcrumbs :items="breadcrumbs"/>

      <v-container>
        <template v-if="action === 'viewPrices'">

          <v-row class="pl-2">
            <v-col cols="12">
              <v-btn color="success" @click="showPriceEditor(-1)">
                <v-icon size="21">mdi-currency-usd</v-icon>
                Add material type
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="pl-2">
            <v-col>
              <v-card elevation="2">
                <v-data-table
                    dense
                    :headers="priceMixesHeaders"
                    :items="priceMixes"
                    item-key="name"
                >
                  <template v-slot:loading>
                    <div class="pb-5">
                      <v-progress-circular
                          :rotate="360"
                          :size="30"
                          :width="1"
                          color="success"
                          indeterminate
                          class="mt-5"
                      >
                      </v-progress-circular>
                    </div>
                  </template>
                  <template v-slot:item.actions="{ item, index }">
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text x-small color="success" @click="showPriceEditor(index)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Update</span>
                    </v-tooltip>

                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text x-small color="error" @click="showDeleteConfirmDlg(item, index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <price-editor
              :mix="currentMix"
              :nomenclatures="nomenclatures"
              v-on:hide="action = 'viewPrices'"
              v-on:updateMix="submitCurrentMix($event)"
          />
        </template>
      </v-container>
    </template>

    <YesNoDlg
        @yes="deleteMix()"
        @no="dlgDeleteConfirm = false"
        :visible="dlgDeleteConfirm"
        :width="350"
        title="Delete item"
        question="You are about to delete this item."
        :processing="flagDeletingItem"
    />

    <axios ref="axios" v-on:finish="handleAxiosResponse($event)"/>
  </div>
</template>

<script>
import PriceEditor from "./PriceEditor";

export default ({
  name: "PriceMixesIndexComponent",

  data: () => ({
    action: 'viewPrices',
    dlgDeleteConfirm: false,
    flagDeletingItem: false,
    loadingPrices: true,
    postingPrices: false,
    breadcrumbs: [
      {text: "Waste partners", disabled: false, href: "/#/waste-partners/waste-partners/all"},
    ],
    mounted: false,
    endpoints: {
      get: 'waste-partners/price-mixes/get/',
      update: 'waste-partners/price-mixes/update-or-create/',
      delete: 'waste-partners/price-mixes/delete/',
    },
    nomenclatures: {},
    priceMixes: [],
    priceMixesHeaders: [
      {text: "ID", value: "id"},
      {text: "Price", value: "price_per_kg"},
      {text: "Title", value: "title"},
      {text: "Parameters Count", value: "parameters_count"},
      {text: "Actions", value: "actions", sortable: false}
    ],
    currentMix: {},
    currentMixIndex: -1,
  }),
  components: {PriceEditor},
  mounted() {
    this.updateEndpoints()
    this.loadPartnerPrices()
  },
  methods: {
    showDeleteConfirmDlg(item, index) {
      this.currentMix = item
      this.currentMixIndex = index;

      this.dlgDeleteConfirm = true
    },
    deleteMix() {
      this.flagDeletingItem = true

      let config = {
        method: 'delete',
        snackbar: false,
        params: {
          mixId: this.currentMix.id
        },
        overlay: true,
        url: this.endpoints.delete,
      };

      this.$refs.axios.submit(config);
    },
    submitCurrentMix(data) {
      let config = {
        method: 'post',
        snackbar: true,
        params: {
          data: data
        },
        overlay: true,
        url: this.endpoints.update,
      };

      this.$refs.axios.submit(config);
    },
    showPriceEditor(index) {
      if (index === -1) {
        this.currentMix = {
          price: 0,
          parameters: [],
        }
      } else {
        this.currentMix = this.priceMixes[index]
      }

      this.currentMixIndex = index
      this.action = 'addPrice'
    },
    updateEndpoints() {
      let partnerId = this.$route.params.waste_partner_id

      this.endpoints.get += partnerId;
      this.endpoints.update += partnerId;
      this.endpoints.delete += partnerId;
    },
    loadPartnerPrices() {
      let config = {
        method: "get",
        snackbar: false,
        overlay: true,
        url: this.endpoints.get,
      };
      this.$refs.axios.submit(config);
    },
    handleAxiosResponse(event) {
      this.loadingPrices = false;

      if (event.data.result.code === 200) {
        let response = event.data.result.response;

        switch (event.url) {
          case this.endpoints.get:
            this.updateBreadcrumbs(response.partnerName)

            this.nomenclatures = response.nomenclatures;
            this.priceMixes = response.priceMixes;
            this.action = 'viewPrices'
            break;
          case this.endpoints.update:
            this.loadPartnerPrices()
            break;
          case this.endpoints.delete:
            this.flagDeletingItem = false
            this.dlgDeleteConfirm = false
            this.priceMixes.splice(this.currentMixIndex, 1)
            break;
          default:
            break;
        }
      } else {
        this.flagDeletingItem = false
        this.loadingPrices = false;
      }
    },
    updateBreadcrumbs(partnerName) {
      this.breadcrumbs.push({text: `Pricing for ${partnerName}`, disabled: true})
      this.mounted = true
    }
  }
});
</script>

<style scoped>
.v-input {
  font-size: .9em;
}

.v-image {
  border: 1px dashed grey;
  height: 150px;
  width: auto;
}
</style>
