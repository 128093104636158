<template>
  <div>
    <v-dialog
        v-model="flagShowParametersForm"
        transition="dialog-top-transition"
        persistent
        max-width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn x-small class="info mb-3" @click="showParametersForm(-1)">Add parameters</v-btn>
      </template>

      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar dense color="blue-grey darken-4" dark>
            Add Parameter
          </v-toolbar>

          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    v-model="currentParameters.barcode"
                    label="Barcode"
                    placeholder="Barcode"
                    dense
                    outlined
                    autofocus
                />
              </v-col>

              <v-col cols="6">
                <v-autocomplete
                    :items="nomenclatures.materials"
                    label="Material type"
                    :rules="[rules.required]"
                    v-model="currentParameters.material_type_id"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-autocomplete
                    v-model="currentParameters.brand_id"
                    :items="nomenclatures.brands"
                    label="Brand"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="6">
                <v-autocomplete
                    v-model="currentParameters.manufacturer_id"
                    :items="nomenclatures.manufacturers"
                    label="Manufacturer"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="currentParameters.volume"
                    label="Volume"
                    placeholder="Volume"
                    dense
                    outlined
                    autofocus
                />
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                small
                color="success"
                :disabled="!currentParameters.material_type_id"
                @click="updateParameter()"
            >
              Accept
            </v-btn>
            <v-btn
                small
                dark
                color="error"
                @click="flagShowParametersForm = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-form ref="materialTypeForm">
      <v-stepper v-model="currentStep" vertical>
        <v-stepper-step :complete="currentStep > 1" step="1">
          Material Type Parameters
          <small>Define parameters for matching products to this price</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-row>
            <v-col>
              <v-data-table
                  dense
                  :headers="mixParametersHeaders"
                  :items="parametersDecoded"
              >
                <template v-slot:loading>
                  <div class="pb-5">
                    <v-progress-circular
                        :rotate="360"
                        :size="30"
                        :width="1"
                        color="success"
                        indeterminate
                        class="mt-5"
                    >
                    </v-progress-circular>
                  </div>
                </template>
                <template v-slot:item.actions="{ item, index }">
                  <v-tooltip top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" text x-small color="success" @click="showParametersForm(index)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Update</span>
                  </v-tooltip>

                  <v-tooltip top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" text x-small color="error" @click="deleteParameter(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn small class="primary mr-2" @click="currentStep = 2">
                Next
              </v-btn>
              <v-btn small dark color="blue-grey darken-4" @click="$emit('hide')">
                Cancel
              </v-btn>
            </v-col>
          </v-row>

        </v-stepper-content>

        <v-stepper-step
            :complete="currentStep > 2"
            step="2"
        >
          Price per kilogram (ZAR cents)
          <small>
            Items matching the conditions defined in step 1 will be processed at this price
          </small>
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-row>
            <v-col cols="12" sm="3" class="mt-2">
              <v-text-field
                  type="number"
                  :rules="[rules.required]"
                  v-model="currentMix.price_per_kg"
                  min="1"
                  label="Set the price per kg here"
                  placeholder="Price per kg"
                  outlined
                  dense
              />
            </v-col>

            <v-col cols="12" sm="3" class="mt-2">
              <v-text-field
                  v-model="currentMix.title"
                  label="Material Type Title"
                  :rules="[rules.required]"
                  placeholder="Title"
                  outlined
                  dense
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn small class="primary mr-2" @click="saveMix()">
                Save
              </v-btn>
              <v-btn small dark class="blue-grey darken-1 mr-2" @click="currentStep = 1">
                Go back
              </v-btn>
              <v-btn small dark color="blue-grey darken-4" @click="$emit('hide')">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper>
    </v-form>
    <snackbar
        :snackbar="flagShowIncompleteMixAlert"
        color="warning"
        message="Your form is incomplete. Please, review it."
    />
  </div>
</template>

<script>
import 'lodash'

export default {
  data() {
    return {
      currentStep: 1,
      flagShowParametersForm: false,
      flagShowIncompleteMixAlert: false,
      mixParametersHeaders: [
        {text: "Material", value: "material"},
        {text: "Barcode", value: "barcode"},
        {text: "Brand", value: "brand"},
        {text: "Manufacturer", value: "manufacturer"},
        {text: "Volume", value: "volume"},
        {text: "Actions", value: "actions", sortable: false}
      ],
      currentMix: {},
      currentParameters: {},
      currentParametersIndex: -1,
      parametersDecoded: [],
      rules: {
        required: (value) => !!value || "This field is required",
      }
    }
  },
  mounted() {
    this.currentMix = Object.assign(this.currentMix, this.mix)
    this.decodeMixParameters()
  },
  props: ['mix', 'nomenclatures'],
  methods: {
    saveMix() {
      this.$refs.materialTypeForm.validate()
      if (this.currentMixIsComplete()) {
        this.flagShowIncompleteMixAlert = false
        this.$emit('updateMix', this.currentMix)
      } else {
        this.flagShowIncompleteMixAlert = true
      }
    },
    currentMixIsComplete() {
      return this.currentMix.price_per_kg > 0
          && this.currentMix.parameters.length > 0
          && this.currentMix.title
    },
    deleteParameter(index) {
      this.currentMix.parameters.splice(index, 1)

      this.decodeMixParameters()
    },
    showParametersForm(index) {
      if (index === -1) {
        this.resetCurrentParameters()
      } else {
        this.currentParameters = this.currentMix.parameters[index]
      }
      this.currentParametersIndex = index

      this.flagShowParametersForm = true
    },
    resetCurrentParameters() {
      this.currentParameters = {}
    },
    updateParameter() {
      this.currentParameters.volume = this.currentParameters.volume
          ? parseFloat(this.currentParameters.volume).toFixed(2)
          : null

      if (this.isNewParameter()) {
        this.currentMix.parameters.push(this.currentParameters)
      }

      this.decodeMixParameters()
      this.resetCurrentParameters()
      this.flagShowParametersForm = false
    },
    isNewParameter() {
      let currentParametersDecoded = this.decodeMixParameter(this.currentParameters)
      let index = this.currentMix.parameters.findIndex(item => {
        return _.isEqual(item, currentParametersDecoded)
      })
      return index === -1
    },
    areEqual(item, compareTo) {
      return item.material_id === compareTo.material_id
    },
    decodeMixParameters() {
      let that = this
      this.parametersDecoded = this.currentMix.parameters.map(function (item) {
        return that.decodeMixParameter(item)
      })
    },
    decodeMixParameter(item) {
      let brandTitle = this.findNomenclatureTitle(this.nomenclatures.brands, item.brand_id)
      let manufacturerTitle = this.findNomenclatureTitle(this.nomenclatures.manufacturers, item.manufacturer_id)
      let materialTitle = this.findNomenclatureTitle(this.nomenclatures.materials, item.material_type_id)

      item.brand = brandTitle
      item.manufacturer = manufacturerTitle
      item.material = materialTitle

      return item
    },
    findNomenclatureTitle(array, itemId) {
      if (itemId) {
        let item = array.find(item => item.id === itemId)
        return item.title
      }
      return ''
    },
  },
}
</script>
