var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.mounted)?[_c('breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('v-container',[(_vm.action === 'viewPrices')?[_c('v-row',{staticClass:"pl-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.showPriceEditor(-1)}}},[_c('v-icon',{attrs:{"size":"21"}},[_vm._v("mdi-currency-usd")]),_vm._v(" Add material type ")],1)],1)],1),_c('v-row',{staticClass:"pl-2"},[_c('v-col',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.priceMixesHeaders,"items":_vm.priceMixes,"item-key":"name"},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"pb-5"},[_c('v-progress-circular',{staticClass:"mt-5",attrs:{"rotate":360,"size":30,"width":1,"color":"success","indeterminate":""}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","x-small":"","color":"success"},on:{"click":function($event){return _vm.showPriceEditor(index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Update")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.showDeleteConfirmDlg(item, index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}],null,false,3909069557)})],1)],1)],1)]:[_c('price-editor',{attrs:{"mix":_vm.currentMix,"nomenclatures":_vm.nomenclatures},on:{"hide":function($event){_vm.action = 'viewPrices'},"updateMix":function($event){return _vm.submitCurrentMix($event)}}})]],2)]:_vm._e(),_c('YesNoDlg',{attrs:{"visible":_vm.dlgDeleteConfirm,"width":350,"title":"Delete item","question":"You are about to delete this item.","processing":_vm.flagDeletingItem},on:{"yes":function($event){return _vm.deleteMix()},"no":function($event){_vm.dlgDeleteConfirm = false}}}),_c('axios',{ref:"axios",on:{"finish":function($event){return _vm.handleAxiosResponse($event)}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }