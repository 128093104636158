<template>
  <index />
</template>

<script>
import Index from '@/components/waste-partners/price-mixes/Index'

export default ({
  name: "WastePartnerPriceMixesView",

  components: {Index},
});
</script>
